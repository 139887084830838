import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SidebarMenuService } from '../shared/sidebar/sidebar-menu.service';
import { AuthenticationService } from '../../services/authentication.service';
import { GeneralEnum } from '../../enums/general.enum';
import { Customer } from '../../models/customer';
import { CustomerService } from '../../services/customer.service';
import { UserBack } from '../../models/userback';
import { ProduitService } from '../../services/produit.service';
import { BasketService } from '../../services/basket.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass'],
})
export class MainComponent implements OnInit {
  public selectedCustomer: Customer;
  public authorizedCustomers: Array<Customer>;
  public flags = [
    { name: 'Français', code: 'fr-FR', image: 'assets/images/flags/fr.svg' },
    { name: 'English', code: 'en-GB', image: 'assets/images/flags/gb.svg' },
    { name: 'Español', code: 'es-ES', image: 'assets/images/flags/es.svg' },
    { name: 'Português', code: 'pt-PT', image: 'assets/images/flags/pt.svg' },
    // { name: 'Deutsch', image: 'assets/images/flags/de.svg' },
    // { name: 'Nederlands', image: 'assets/images/flags/nl.svg' },
  ];
  public flag: any;
  public indexProduct: number;
  public banners = [];
  public url: any;

  constructor(
    public router: Router,
    public sidenavMenuService: SidebarMenuService,
    private customerService: CustomerService,
    private authenticationService: AuthenticationService,
    private produitService: ProduitService,
    
    private basketService: BasketService,
    private translateService: TranslateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    this.authorizedCustomers = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_CUSTOMERS_LIST)
    );
    this.selectedCustomer = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_CUSTOMER)
    );

    this.flags.forEach((f) => {
      if (f.code === langue) {
        this.flag = f;
      }
    });

    if (this.flag == null) {
      this.flag = this.flags[0];
    }
  }

  public changeCustomer(customer: Customer) {
    this.customerService.changeCustomer({ id: customer.id }).subscribe({
      next: (value) => {
        this.selectedCustomer = customer;
        localStorage.setItem(
          GeneralEnum.STORAGE_CUSTOMER,
          JSON.stringify(customer)
        );

        const user: UserBack = JSON.parse(
          localStorage.getItem(GeneralEnum.STORAGE_USER)
        );
        user.customer_selected = customer.id;
        localStorage.setItem(GeneralEnum.STORAGE_USER, JSON.stringify(user));

        this.produitService.filtersClassCount().subscribe({
          next: (result) => {
            localStorage.setItem(
              GeneralEnum.STORAGE_FILTERS_CLASS_COUNT,
              JSON.stringify(result)
            );
          },
          error: (err) => {
            console.log(
              'Erreur du mise à jour du nombre de références par classe etim : ' +
                err
            );
          },
        });

        this.authenticationService
          .refreshInfosUser()
          .then((result) => {
            if (localStorage.getItem(GeneralEnum.STORAGE_SELECTION)) {
              localStorage.removeItem(GeneralEnum.STORAGE_SELECTION);
            }

            // Récupération du panier temporaire
            this.basketService.reloadBasketInContext(customer.id).then((r) => {
              // console.log('RELOAD !!!');
              // window.location.reload();
            });
          })
          .catch(
            (x) => 'Impossible de mettre à jour les informations utilisateur'
          );

        // this.authenticationService.refreshToken().catch(x => 'Impossible de rafraichir le token de session');
        // window.location.reload();
      },
      error: (err) => {
        console.log('Erreur changement de client : ' + err);
      },
    });
  }

  public changeLang(flag) {
    this.flag = flag;

    this.authenticationService.saveUserLanguage(flag.code).subscribe({
      next: (value) => {
        localStorage.setItem(GeneralEnum.STORAGE_LANGUAGE, flag.code);
        this.translateService.setDefaultLang(flag.code);
        this.translateService.use(flag.code);
        window.location.reload();
      },
      error: (err) => {
        console.log('Erreur changement de langue du client : ' + err);
        window.location.reload();
      },
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['login']);
  }
}
